<template>
    <div class="box">
        <div class="search">
            <div class="search-top">
                <div v-for="(item,i) in toplist" :key="i">{{item.name}}（{{item.value}}）</div>
            </div>
            <el-form label-width="120px" label-height="60px">
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="商家ID：" prop="id">
                      <el-input
                        v-model="queryInfo.condition.id"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="商家名称：" prop="businessName">
                      <el-input
                        v-model="queryInfo.condition.businessName"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="申请时间：" prop="applicantTime">
                      <el-input
                        v-model="queryInfo.condition.applicantTime"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">
                    <el-form-item label="联系电话：" prop="linkPhone">
                      <el-input
                        v-model="queryInfo.condition.linkPhone"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8">
                    <el-form-item label="经营范围：" prop="scope">
                      <el-input
                        v-model="queryInfo.condition.scope"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="8" style="display: flex; justify-content: flex-end">
                    <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
                    <el-button type="primary" @click="search" icon="el-icon-search"
                      >搜索</el-button
                    >
                </el-col>
                  <!-- <el-col :span="8">
                    <el-form-item label="行业分类：" prop="industryType">
                      <el-input
                        v-model="queryInfo.condition.industryType"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                  </el-col> -->
                </el-row>
              <!-- <el-row>
                <el-col :span="8">
                    <el-form-item label="地区：" prop="industryType">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="商家状态：" prop="businessStatus">
                      <el-input
                        v-model="queryInfo.name"
                        placeholder="请输入"
                        clearable
                      ></el-input>
                    </el-form-item>
                </el-col>
               
                <el-col :span="8" style="display: flex; justify-content: flex-end">
                    <el-button @click="reset" icon="el-icon-refresh-left">重置</el-button>
                    <el-button type="primary" @click="search" icon="el-icon-search"
                      >搜索</el-button
                    >
                </el-col>
               
              </el-row> -->
            </el-form>
        </div>
        <div class="tab">
          <div class="add" style="text-align: right;">
            <input id="file-input" type="file" @change="importClick" style="display: none;">
              <el-button
                type="primary"
                onclick="document.getElementById('file-input').click();">
                商家信息导入
              </el-button>
              <el-button
                type="primary"
                @click="toExport">
                导出收款码
              </el-button>
          </div>
            <el-table
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 400px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
        <el-table-column
          prop="id"
          label="商家ID"
          width="160"
          align="center"
        >
         
        </el-table-column>
        <el-table-column
          prop="businessName"
          label="商家名称"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="businessAccount"
          label="商家账号"
          width="200"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="paymentCode"
          label="收款码"
          align="center"
        >
        <template slot-scope="scope">
            <img style="width: 50px;height: 50px;" :src="scope.row.paymentCode" alt="">
        </template>
        </el-table-column>
        <el-table-column
          prop="allowPayoutsNum"
          label="可提现金额"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="accountTerm"
          label="账期"
         
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="payoutsNum"
          label="提现中金额"
          width="100"
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="payoutsTotalNum"
          label="已提现总金额"
          width="100"
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="linkPhone"
          label="联系电话"
          width="150"
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="scope"
          label="经营范围"
         
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="industryType"
          label="行业分类"
         
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="industryType"
          label="所在地区"
         
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="businessStatus"
          label="商家状态"
         
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.businessStatus==1">自动营业</span>
            <span v-if="scope.row.businessStatus==2">自动打烊</span>
            <span v-if="scope.row.businessStatus==3">手动营业</span>
            <span v-if="scope.row.businessStatus==4">手动打烊</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="licenceImg"
          label="营业执照"
         
          align="center"
        >
        <template slot-scope="scope">
            <img style="width: 50px;height: 50px;" :src="scope.row.licenceImg" alt="">
        </template>
        </el-table-column>
        <el-table-column
          prop="idCardImgFront"
          label="身份证正面"
          width="100px"
          align="center"
        >
        <template slot-scope="scope">
            <img style="width: 50px;height: 50px;" :src="scope.row.idCardImgFront" alt="">
        </template>
        </el-table-column>
        <el-table-column
          prop="idCardImgReverse"
          label="身份证反面"
         width="100px"
          align="center"
        >
        <template slot-scope="scope">
            <img style="width: 50px;height: 50px;" :src="scope.row.idCardImgReverse" alt="">
        </template>
        </el-table-column>
        <el-table-column
          prop="qualifications"
          label="资质证书"
         
          align="center"
        >
          <template slot-scope="scope">
              <img style="width: 50px;height: 50px;" :src="scope.row.qualifications" alt="">
          </template>
        </el-table-column>
        <el-table-column
          prop="environmentImg"
          label="经营场景"
         
          align="center"
        >
        <template slot-scope="scope">
              <img style="width: 50px;height: 50px;" :src="scope.row.environmentImg" alt="">
          </template>
        </el-table-column>
        <el-table-column
          prop="applicantTime"
          label="申请时间"
          width="150"
          align="center"
        >

        </el-table-column>
        <el-table-column
          prop="auditStatus"
          label="审核状态"
         
          align="center"
        >
        <template slot-scope="scope">
            <el-button type="text" v-if="scope.row.auditStatus==1">审核中</el-button>
            <el-button type="text" v-else-if="scope.row.auditStatus==2">审核拒绝</el-button>
            <el-button type="text" v-else>审核通过</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop=" "
          label="操作"
          width="200"
          fixed="right"
          align="center"
        >
        <template slot-scope="scope">
            <el-button type="text" @click="deta(scope.row.id)">详情</el-button>
            <el-button type="text" style="color: red;"  @click="sh(scope.row.id)" >审核</el-button>
            
          </template>


        </el-table-column>


            </el-table>  
            <div class="pagination">
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page.sync="queryInfo.currPage"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
              >
              </el-pagination>
            </div>
        </div> 
        <!-- 详情弹窗 -->
        <el-drawer
          title="商家详情"
          :visible.sync="drawer"
          direction="rtl"
          size="60%"
          :before-close="handleClose">
          <div class="drawer">
            <div class="drauser">
                <div><span>商家ID:</span> {{businessdetail.id }}</div>
                <div><span>商家名称:</span> {{businessdetail.businessName }}</div>
                <div><span>法定代表人:</span> {{businessdetail.userName }}</div>
                <div><span>法人联系电话:</span> {{businessdetail.legalerPhone }}</div>
                <div><span>经营地址:</span> {{businessdetail.provinceName }}</div>
                <div><span>经营范围:</span> {{businessdetail.scope}}</div>
                <div><span>行业分类:</span> {{businessdetail.industryType }}</div>
                <div><span>注册地址:</span> {{businessdetail.registeredAddress }}</div>
                <div><span>营业执照有效期:</span>{{businessdetail.licenseValidityDate }}</div>
                <div><span>连锁编码:</span> {{businessdetail.chainCode }}</div>
            </div>
            <div class="draimg">
                <div class="imgbox">
                    <div class="imgname">身份证:</div>
                    <div class="img">
                      <img :src="businessdetail.idCardImgFront" alt="">
                      <img :src="businessdetail.idCardImgReverse" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="imgname">营业执照:</div>
                    <div class="img">
                       <img :src="businessdetail.logoUrl" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="imgname">资历证书:</div>
                    <div class="img">
                      <img :src="businessdetail.qualifications" alt="">
                        
                    </div>
                </div>
                <div class="imgbox">
                    <div class="imgname">经营场景:</div>
                    <div class="img">
                      <img :src="businessdetail.qualifications" alt="">
                    </div>
                </div>
                
            </div>
            <div class="bl">
                <div class="bl-li">
                    <div class="bl-name">积分使用比例：</div>
                    <div class="bls">
                        <div>消费</div>
                        <el-input style="width: 150px;" v-model="detaillist.integralRatioUse"  type="text"><template slot="append">元</template></el-input>
                        
                    </div>
                    <div class="bls">
                        <div>获得</div>
                        <el-input style="width: 150px;" v-model="detaillist.integralRatioGet" type="text"><template slot="append">积分</template></el-input>
                        
                    </div>
                </div>
                <div class="bl-li">
                    <div class="bl-name">抽佣比例：</div>
                    <div class="bls">
                        <div>外卖</div>
                        &nbsp;
                        <!-- <el-input-number v-model="detaillist.takeawayRake" :controls="false" :min="0" :max="99" style="padding-left: 15px;"></el-input-number> % -->
                        <el-input style="width: 150px;padding-left: 8px;" v-model="detaillist.takeawayRake" type="text" οnkeyup="value=value.replace(/[^\d||/.]/g,'')"
                          οninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=0} if(value>100){value=100}" :maxlength="2" :minlength="1">
                          <template slot="append">%</template>
                        </el-input>
                        
                    </div>
                    <div class="bls">
                        <div>团购</div>
                        &nbsp;
                        <!-- <el-input-number v-model="detaillist.buyRake" :controls="false" :min="0" :max="99" style="padding-left: 15px;"></el-input-number> % -->
                        <el-input style="width: 150px;padding-left: 8px;" v-model="detaillist.buyRake" type="text" οnkeyup="value=value.replace(/[^\d||/.]/g,'')"
                          οninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=0} if(value>100){value=100}" :maxlength="2" :minlength="1">
                          <template slot="append">%</template>
                        </el-input>
                        
                    </div>
                    <div class="bls">
                        <div>到店扫码</div>
                        &nbsp;
                        <!-- <el-input-number v-model="detaillist.yardRake" :controls="false" :min="0" :max="99" style="padding-left: 15px;"></el-input-number> % -->
                        <el-input style="width: 150px;padding-left: 8px;" v-model="detaillist.yardRake" type="text" οnkeyup="value=value.replace(/[^\d||/.]/g,'')"
                          οninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=0} if(value>100){value=100}" :maxlength="2" :minlength="1">
                          <template slot="append">%</template>
                        </el-input>
                        
                    </div>
                </div>
                <div class="bl-li">
                    <div class="bl-name">账期设置：</div>
                    <div class="bls">
                        <div>账期</div>
                        <el-input style="width: 150px;padding-left: 8px;" v-model="detaillist.accountTerm" type="text"><template slot="append">天</template></el-input>
                        
                    </div>
                  
                </div>
            </div>
            <div class="d-config">
              <div class="qx">
                <el-switch
                  :width="30"
                  height="10"
                  v-model="detaillist.integralDeduceAuthority"
                  @change = 'change'
                  inactive-text="积分抵扣权限">
                </el-switch>
                <el-switch
                  v-model="detaillist.platformDeliveryAuthority"
                  inactive-text="平台配送权限">
                </el-switch>
                <!-- <el-switch
                  v-model="detaillist.couponVerificateAuthority"
                  inactive-text="优惠券核销权限">
                </el-switch> -->
                <el-switch
                  v-model="detaillist.selfDeliveryAuthority"
                  inactive-text="商家自配送权限">
                </el-switch>
              </div>
              <div class="qx title1">外卖配置</div>
              <div class="qx title2"><span style="color: red;">*</span>配送方式</div>
              <div class="qx">
                <div class="qx-item">
                  <el-switch
                    v-model="detaillist.deliveryMethodPlatform"
                    :active-value="1" :inactive-value="0"
                    inactive-text="平台配送">
                  </el-switch>
                  <span v-if="detaillist.deliveryMethodPlatform">
                    <span style="color: red;">*</span>
                    配送费承担比例&nbsp;
                    <!-- <el-input-number v-model="detaillist.proportionWm" :controls="false" :min="0" :max="99" style="padding-left: 15px;"></el-input-number> % -->
                    <el-input style="width: 150px;padding-left: 8px;" v-model="detaillist.proportionWm" type="text" οnkeyup="value=value.replace(/[^\d||/.]/g,'')"
                      οninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=0} if(value>100){value=100}" :maxlength="2" :minlength="1">
                      <template slot="append">%</template>
                    </el-input>
                  </span>
                </div>
                <div class="qx-item">
                  <el-switch
                    v-model="detaillist.deliveryMethod"
                    :active-value="1" :inactive-value="0"
                    inactive-text="自配送">
                  </el-switch>
                  <el-switch
                    v-model="detaillist.deliveryMethodStore"
                    :active-value="1" :inactive-value="0"
                    inactive-text="到店自取">
                  </el-switch>
                </div>
              </div>
              <div class="qx title2"><span style="color: red;">*</span>接单配置</div>
              <div class="qx">
                <div class="qx-item">
                  <el-switch
                    v-model="detaillist.isAutoOrderTake"
                    :active-value="1" :inactive-value="2"
                    inactive-text="是否支持外卖自动接单">
                  </el-switch>
                </div>
              </div>
              <div class="qx title2"><span style="color: red;">*</span>预约配置</div>
              <div class="qx">
                <div class="qx-item">
                  <el-switch
                    v-model="detaillist.wmBooking"
                    :active-value="1" :inactive-value="0"
                    inactive-text="是否支持预约">
                  </el-switch>
                </div>
              </div>
              <div class="qx title1">团购配置</div>
              <div class="qx title2"><span style="color: red;">*</span>配送管理</div>
              <div class="qx">
                <div class="qx-item">
                  <el-switch
                    v-model="setGroupDeliveryStore"
                    :active-value="1" :inactive-value="0"
                    @change="changeDelivery(2,setGroupDeliveryStore)"
                    inactive-text="到店核销">
                  </el-switch>
                  <el-switch
                    v-model="setGroupDelivery"
                    :active-value="1" :inactive-value="0"
                    @change="changeDelivery(1,setGroupDelivery)"
                    inactive-text="配送">
                  </el-switch>
                </div>
              </div>
              <div class="qx title2" v-if="setGroupDeliveryStore || setGroupDelivery"><span style="color: red;">*</span>配送方式</div>
              <div class="qx">
                <div class="qx-item" v-if="setGroupDelivery == 1">
                  <el-switch
                    v-model="detaillist.groupDeliveryPlatform"
                    :active-value="1" :inactive-value="0"
                    inactive-text="平台配送">
                  </el-switch>
                  <span v-if="detaillist.groupDeliveryPlatform">
                    <span style="color: red;">*</span>配送费承担比例&nbsp;
                    <!-- <el-input-number v-model="detaillist.proportion" :controls="false" :min="0" :max="99" style="padding-left: 15px;"></el-input-number> % -->
                    <el-input style="width: 150px;padding-left: 8px;" v-model="detaillist.proportion" type="text" οnkeyup="value=value.replace(/[^\d||/.]/g,'')"
                      οninput="if(value){value=value.replace(/[^\d]/g,'')} if(value<=0){value=0} if(value>100){value=100}" :maxlength="2" :minlength="1">
                      <template slot="append">%</template>
                    </el-input>
                  </span>
                </div>
                <div class="qx-item">
                  <el-switch
                    v-model="detaillist.groupDeliveryBusiness"
                    v-if="setGroupDelivery == 1"
                    :active-value="1" :inactive-value="0"
                    inactive-text="自配送">
                  </el-switch>
                  <el-switch
                    v-model="detaillist.groupDeliveryStore"
                    v-if="setGroupDeliveryStore == 1"
                    :active-value="1" :inactive-value="0"
                    inactive-text="到店核销">
                  </el-switch>
                </div>
              </div>
              <div class="qx title2"><span style="color: red;">*</span>接单配置</div>
              <div class="qx">
                <div class="qx-item">
                  <el-switch
                    v-model="detaillist.isAutoTakeGroup"
                    :active-value="1" :inactive-value="2"
                    inactive-text="是否支持配送自动接单">
                  </el-switch>
                </div>
              </div>
              <div class="qx title2"><span style="color: red;">*</span>预约配置</div>
              <div class="qx">
                <div class="qx-item">
                  <el-switch
                    v-model="detaillist.tgBooking"
                    :active-value="1" :inactive-value="0"
                    inactive-text="是否支持预约">
                  </el-switch>
                </div>
              </div>
              <div class="but" style="margin-top: 20px;">
                   <el-button type="danger" @click="drawer=false">取消 </el-button>
                   <el-button type="primary" @click="detailok()">保存</el-button>
              </div>
            </div>
            <div class="drwdetail">
                <div style="padding-left: 20px;">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                      <el-tab-pane label="余额存入记录" name="first">
                        <div>
                            <el-table
                              :data="yedetail"
                              style="width: 100%"
                              
                              height="calc(100vh - 380px)"
                              border
                              :header-cell-style="{
                                'background-color': '#f2f2f2',
                                'text-align': 'center',
                                border: '1px solid #ddd',
                              }"
                            >
                              <el-table-column
                                prop="transaction"
                                label="交易说明"
                               
                                align="center"
                              >
                              <template slot-scope="scope">
                                <span>{{ scope.row.transaction ==1?'提现': 
                                          scope.row.transaction ==1?'订单结算':
                                          scope.row.transaction ==1?'订单退款':
                                          scope.row.transaction ==1?'运费':'平台抽成'}}</span>
                              </template>
                              </el-table-column>
                              <el-table-column
                                prop="creatTime"
                                label="存入时间"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="amount"
                                label="存入金额"
                               
                                align="center"
                              >
                              </el-table-column>
                              
                            </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="提现明细" name="second">
                        <div>
                            <el-table
                              :data="txdetail"
                              style="width: 100%"
                              
                              height="calc(100vh - 580px)"
                              border
                              :header-cell-style="{
                                'background-color': '#f2f2f2',
                                'text-align': 'center',
                                border: '1px solid #ddd',
                              }"
                            >
                              <el-table-column
                                prop="payoutsTotalNum"
                                label="提现金额"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="creatTime"
                                label="提现日期"
                               
                                align="center"
                              >
                              </el-table-column>
                             
                              <el-table-column
                                prop="payoutsStatus"
                                label="审核状态"
                               
                                align="center"
                              >
                              <template slot-scope="scope">
                                <span>{{ scope.row.payoutsStatus ==1?'提现中': 
                                          scope.row.payoutsStatus ==2?'提现成功':'提现失败'
                                        }}</span>
                              </template>
                              </el-table-column>
                              <el-table-column
                                prop="creatTime"
                                label="提现审核通过日期"
                               
                                align="center"
                              >
                              </el-table-column>
                            </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="评论明细" name="third">
                        <div>
                            <el-table
                              :data="pjdetail"
                              style="width: 100%"
                              
                              height="calc(100vh - 580px)"
                              border
                              :header-cell-style="{
                                'background-color': '#f2f2f2',
                                'text-align': 'center',
                                border: '1px solid #ddd',
                              }"
                            >

                              <el-table-column
                                prop="orderId"
                                label="关联订单"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="content"
                                label="评论内容"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="appraiseImg"
                                label="评论图片视频"
                               
                                align="center"
                              >
                              <template slot-scope="scope">
                                  <el-image style="width: 50px;height: 50px;" :src="scope.row.appraiseImg" :preview-src-list="scope.row.appraiseImg" />
                              </template>
                              </el-table-column>
                              <el-table-column
                                prop="appraise"
                                label="评分"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="creatTime"
                                label="评论时间"
                               
                                align="center"
                              >
                              </el-table-column>
                              
                            </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="店铺明细" name="fourth">
                        <div>
                            <el-table
                              :data="dpdetail"
                              style="width: 100%"
                              
                              height="calc(100vh - 580px)"
                              border
                              :header-cell-style="{
                                'background-color': '#f2f2f2',
                                'text-align': 'center',
                                border: '1px solid #ddd',
                              }"
                            >
                              <el-table-column
                                prop="name"
                                label="店铺头像"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="店铺名称"
                                label="邀请人手机号"
                               
                                align="center"
                              >
                              </el-table-column>
                              <el-table-column
                                prop="name"
                                label="店铺地址"
                               
                                align="center"
                              >
                              </el-table-column>
                              
                              
                            </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="合同管理" name="five">
                        <div>
                            <div style="height: 30px;line-height: 30px; padding: 2px 2px 2px 2px;border-radius: 5px;background-color: rgba(223, 243, 255, 0.56);">{{ htdetail.status==0?'未签署':'已签署' }} {{ htdetail.creatTime }}</div>
                            <div>
                              <img @click="go(htdetail)" style="width: 35px;height: 35px;" src="../../assets/images/ht.png" alt="">
                              <div>{{ htdetail.contractName }}</div>
                            </div>
                        </div>
                      </el-tab-pane>
                    </el-tabs>
                    
                </div>
               
            </div>
          </div>
        </el-drawer>
        <el-dialog
          title="店铺审核详情"
          :visible.sync="showDialogtwo"
          width="1000px"
          height="1000px"
        >
          <div class="diatwo">
            <div class="dialist">
                <div class="dia-li">
                    <div class="name">店铺头像</div>
                    <img :src="businessdetail.logoUrl " alt="">
                </div>
                <div class="dia-li">
                    <div class="name">店铺名称</div>
                    <div>{{businessdetail.businessName }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">店铺账号</div>
                    <div>{{businessdetail.applicantPhone }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">店铺地区</div>
                    <div>{{businessdetail.provinceName }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">业务管理</div>
                    <div>{{businessdetail.scope }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">行业分类</div>
                    <div>{{businessdetail.storeClassification }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">营业时间</div>
                    <div>{{businessdetail.businessStartTime }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">法定代表人</div>
                    <div>{{businessdetail.userName }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">联系电话</div>
                    <div>{{ businessdetail.linkPhone }}</div>
                </div>
                <div class="dia-li">
                    <div class="name">连锁编码</div>
                    <div>{{ businessdetail.linkPhone }}</div>
                </div>
                
            </div>
            <div class="diaimg">
                <div class="imgbox">
                    <div class="name">身份证扫描件</div>
                    <div class="imgli">
                        <img :src="businessdetail.idCardImgFront" alt="">
                        <img :src="businessdetail.idCardImgReverse" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="name">营业执照</div>
                    <div class="imgli">
                        <img :src="businessdetail.logoUrl" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="name">资质许可证</div>
                    <div class="imgli">
                        <img :src="businessdetail.qualifications" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="name">经营场景</div>
                    <div class="imgli">
                        <img :src="businessdetail.qualifications" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="name">荣誉证书</div>
                    <div class="imgli">
                        <img :src="businessdetail.qualifications" alt="">
                    </div>
                </div>
            </div>
            <div class="diaimg">
                <div class="imgbox">
                    <div class="name">店铺环境照</div>
                    <div class="imgli">
                        <img :src="businessdetail.environmentImg" alt="">
                        <img :src="businessdetail.headImg " alt="">
                        <img :src="businessdetail.lobbyImg" alt="">
                    </div>
                </div>
                
            </div>
            <div style="margin-top: 40px;">是否同意该商家店铺入驻小尼甄选平台？</div>
            <div class="sh">
                <div >审核备注 :</div>
                <textarea v-model="shyj" name="" id=""></textarea>
            </div>
            <div class="but">
                <el-button type="danger" @click="nook()">不同意 </el-button>
                <el-button type="primary" @click="ok()">同意 </el-button>

            </div>
            
          </div>
        </el-dialog> 
        <el-dialog title="查看合同" :visible.sync="showCDialog" min-width="800px" width="1000px" height="600px">
      <div class="view-box">
        <div class="imgli" v-for="(item,index) in showCImgList" :key="index">
          <img :src="item" alt="">
        </div>
      </div>
    </el-dialog>
    </div>
    
  </template>
  
  <script>
 export default {
  data() {
    return {
        showDialogtwo:false,
        activeName: 'second',
        drawer:false,
        toplist:[{
            name:'全部',
            value:''
        },{
            name:'待审核',
            value:''
        },{
            name:'已通过',
            value:''
        },{
            name:'已拒绝',
            value:''
        }],
        tableData:[{
            id:122,
            businessName:'十八点',
            allowPayoutsNum:1888,
            payoutsTotalNum:8821,
            linkPhone:15088762311,
            scope:'和您当前',
            industryType:1,
            businessStatus:1,
            licenceImg:'./img',
            idCardImgFront:'./img',
            idCardImgReverse:'./img',
            qualifications:'./img',
            applicantTime:'1998-09-80',
            auditStatus:'2'




        }],
        queryInfo:{
          condition: {
            id:'',
            businessName:'',
            applicantTime:'',
            linkPhone:'',
            scope:'',
            industryType:'',
            businessStatus:''
          },
	        currPage: 1,
	        pageCount: 1,
	        pageSize: 10
         
        },
        total :0,
        showDialog:false,
        businessdetail:{},
        id:'',
        value1:0,
        shyj:'',
        detaillist:{
          integralRatioUse:'',
          integralRatioGet:'',
          takeawayRake:'',
          buyRake:'',
          yardRake:'',
          accountTerm:'',
          integralDeduceAuthority:'',
          platformDeliveryAuthority:'',
          couponVerificateAuthority:'',
          selfDeliveryAuthority:''
        },
        yedetail:[],
        txdetail:[],
        pjdetail:[],
        htdetail:{},
        dpdetail:[],
        showCImgList:'',
        showCDialog:false,
        setGroupDeliveryStore:0,
        setGroupDelivery:0,
    };
  },
  mounted() {
    this.getList ()
    this.uap()
  },
  methods: {
    uap(){
      var that = this;
	    that.$http.post("/business/generatePaymentCodeBatch", {}).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
        
        }
      });
      
    },
    change(e){
      console.log(e);
    },
    added(){
        this.$router.push({path:'/staffDetail'})
    },
    async importClick(event){
      console.log('导入',event)
      const that = this
      const file = event.target.files[0];
      // 使用 FormData 包装文件
      const formData = new FormData();
      formData.append('file', file);
      const res = await this.$http.post("importExcel/importBusiness", formData, {
        headers: {
            'Content-Type': 'multipart/form-data', // 设置正确的Content-Type
        },
      });
      if(res.data.code === 200){
          this.getList()
          that.$notify.success({
            title: "提示",
            message: "导入成功",
            showClose: true,
          });
      }else{
          this.$notify.info({
            title: "提示",
            message: res.data.message,
            showClose: true,
          });
      }
    },
    // 导出
    async toExport(){
      const that = this
      const _data = {
        ...that.queryInfo.condition
      }
      const res = await this.$http({
        method: 'post',
        url: '/business/generatePaymentCodeBatch',
        responseType: "blob",
        data: _data
      });
      if (res.status == 200) {
        try {
          const blob = new Blob([res.data], { type: 'application/zip' }); // 创建Blob对象
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download", `商家二维码.zip`); // 设置导出的文件名称
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          that.$message({type:'success',message: '导出成功'})
        } catch (error) {
          this.$message.error(error);
        }
      }
    },
    //获取数据
    getList () {
	    var that = this;
	    that.$http.post("/business/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },
    search(){
      this.queryInfo.currPage=1;
      this.getList()
    },
    reset(){
      this.queryInfo.condition = {
        id:'',
        businessName:'',
        applicantTime:'',
        linkPhone:'',
        scope:'',
        industryType:'',
        businessStatus:''
      }
      this.queryInfo.currPage=1;
      this.getList()
    },
    sh(id){
      this.showDialogtwo = true
      this.id = id
      var that = this;
      
	    that.$http.post("/business/queryBusinessDetail",{
        id:id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.businessdetail = response.data.data
        }
      });
      
    },
    handleClose(done){
      console.log('handleClose')
      this.drawer = false
      done()
    },
    deta(id){
      this.drawer=true
     
      this.id = id
      var that = this;
      
	    that.$http.post("/business/queryBusinessDetail",{
        id:id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.setGroupDeliveryStore = response.data.data.groupDeliveryStore == 1?1:0
          that.setGroupDelivery = (response.data.data.groupDeliveryPlatform == 1 || response.data.data.groupDeliveryBusiness == 1)?1:0
          that.businessdetail = response.data.data
          that.detaillist = response.data.data
          console.log('deta----00---->',that.businessdetail,that.detaillist)
          if(that.detaillist.integralDeduceAuthority == 0){
            that.detaillist.integralDeduceAuthority = false
          } else {
            that.detaillist.integralDeduceAuthority = true
          }
          if(that.detaillist.platformDeliveryAuthority == 0){
            that.detaillist.platformDeliveryAuthority = false
          } else {
            that.detaillist.platformDeliveryAuthority = true
          }
          if(that.detaillist.couponVerificateAuthority == 0){
            that.detaillist.couponVerificateAuthority = false
          } else {
            that.detaillist.couponVerificateAuthority = true
          }
          if(that.detaillist.selfDeliveryAuthority == 0){
            that.detaillist.selfDeliveryAuthority = false
          } else {
            that.detaillist.selfDeliveryAuthority = true
          }

          that.detaillist.takeawayRake = Math.floor(response.data.data.takeawayRake*100)
          that.detaillist.buyRake = Math.floor(response.data.data.buyRake*100)
          that.detaillist.yardRake = Math.floor(response.data.data.yardRake*100)
          
          that.detaillist.proportionWm = Math.floor(response.data.data.proportionWm*100)
          that.detaillist.proportion = Math.floor(response.data.data.proportion*100)
          // that.detaillist.integralRatioUse = that.businessdetail.integralRatioUse
          // that.detaillist.integralRatioGet = that.businessdetail.integralRatioGet
          // that.detaillist.takeawayRake = that.businessdetail.takeawayRake
          // that.detaillist.buyRake = that.businessdetail.buyRake
          // that.detaillist.yardRake = that.businessdetail.yardRake 
          // that.detaillist.accountTerm = that.businessdetail.accountTerm
          // that.detaillist.integralDeduceAuthority = that.businessdetail.integralDeduceAuthority
          // that.detaillist.platformDeliveryAuthority = that.businessdetail.platformDeliveryAuthority
          // that.detaillist.couponVerificateAuthority = that.businessdetail.couponVerificateAuthority
          // that.detaillist.selfDeliveryAuthority = that.businessdetail.selfDeliveryAuthority
        }
      });
      //余额存入明细
      that.$http.post("/businessTransaction/queryList",{
        transactionType:1,
        businessId:id,
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.yedetail = response.data.data
        }
      });
      //提现明细
      that.$http.post("/businessTransaction/queryList",{
        transactionType:1,
        businessId:id,
        transaction:1,
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.txdetail = response.data.data
        }
      });
      //评价
      that.$http.post("/businessAppraise/queryList",{
        businessId:id
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.pjdetail = response.data.data
        }
      });
      //合同
      that.$http.post("/businessContract/queryOne",{
        businessId:id
      }).then(function (response) {
        console.log(response.data.data,'合同');
        if (response.data.code == 200) {
          that.htdetail = response.data.data || {}
        }
      });
      
    },
    changeDelivery(index,value){
      switch(index){
        case 1:
          this.detaillist.groupDeliveryPlatform = (value == 1)?1:0
          this.detaillist.groupDeliveryBusiness = (value == 1)?1:0
          break;
        case 2:
          this.detaillist.groupDeliveryStore = value == 1?1:0
          break;
      }
    },
    go(row) {
      console.log(row);
      this.showCImgList = row.contractModel.split(',')
      // window.location.href = row.contractFile
      this.showCDialog = true
    },
    //审核
    ok(){
      var that = this;
	    that.$http.post("/business/auditBusiness", {
        id:that.id,
        auditRemark:this.shyj,
        auditStatus:3
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.showDialogtwo = false
          that.getList()
          console.log(response.data.data);
        }
        
      });
    },
    nook(){
      var that = this;
	    that.$http.post("/business/auditBusiness", {
        id:that.id,
        auditRemark:this.shyj,
        auditStatus:2
      }).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          console.log(response.data.data);
          that.showDialogtwo = false
          that.getList()
        }
      });
    },
    handleClick(e){},
    detailok(){
      var that = this;
      if(this.detaillist.integralDeduceAuthority == true){
        this.detaillist.integralDeduceAuthority = 1
      } else {
        this.detaillist.integralDeduceAuthority = 0
      }
      if(this.detaillist.platformDeliveryAuthority == true){
        this.detaillist.platformDeliveryAuthority = 1
      } else {
        this.detaillist.platformDeliveryAuthority = 0
      }
      if(this.detaillist.couponVerificateAuthority == true){
        this.detaillist.couponVerificateAuthority = 1
      } else {
        this.detaillist.couponVerificateAuthority = 0
      }
      if(this.detaillist.selfDeliveryAuthority == true){
        this.detaillist.selfDeliveryAuthority = 1
      } else {
        this.detaillist.selfDeliveryAuthority = 0
      }
      that.detaillist.takeawayRake = that.detaillist.takeawayRake/100
      that.detaillist.buyRake = that.detaillist.buyRake/100
      that.detaillist.yardRake = that.detaillist.yardRake/100
      
      that.detaillist.proportionWm = that.detaillist.proportionWm/100
      that.detaillist.proportion = that.detaillist.proportion/100
      const detaillist = that.detaillist
      detaillist.isAdmin = 2
	    that.$http.post("/business/saveBusiness",
        detaillist
      ).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.drawer = false
          that.getList()
          that.$message.success("保存成功");
          
        } else {
          that.$message.success(response.data.message)
        }
        
      });
    },
          // 修改页数大小
          handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },
  }
};
  </script>
  
  <style lang="less" scoped>
  .view-box{
  flex: 1;
  // height: 540px;
  min-height: 540px;
  max-height: calc(85vh - 180px);
  overflow-y: auto;
  .imgli{
    flex: 1;
    img{
      width: 100%;
      object-fit: contain;
    }
  }
}
  /deep/ .el-tabs__header {
    margin: 0;
  }
  .box {
    .search {
        background-color: #fff;
        box-sizing: border-box;
        padding: 15px 15px;
        .search-top {
            padding: 5px;
            background-color: rgba(30, 152, 215, 0.14);
            font-size: 15px;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            div {
                width: 150px;
                text-align: center;
            }
        }
    }
    .tab {
        background-color: #fff;
        margin-top: 15px;
        padding: 15px
    }
    .dia {
      .diafour {
            display: flex;
            justify-content: space-between;
            padding: 20px 130px;
        }
    }
    .drawer {
        height: 900px;
        overflow-y: scroll;
        font-size: 12px;
        .drauser {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0 30px;
            box-sizing: border-box;
            div {
                width: 30%;
                color: #847575;
                margin-bottom: 10px;
                span {
                    color: #000;
                }
            }
        }
        .draimg {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 0 30px;
            .imgbox {
                width: 30%;
                display: flex;
               
                .imgname {
                    color: #000;
                }
                .img {
                    margin-top: 10px;
                    display: flex;
                    img {
                        width: 89px;
                        height: 65px;
                    }
                }

            }
        }
        .bl {
            padding: 0 30px;
            .bl-li {
                display: flex;
                align-items: center;
                margin-top: 5px;
                .bls {
                    display: flex;
                    align-items: center;
                    margin-left: 15px;
                    input {
                        width: 100px;
                        height: 32px;
                        text-align: center
                    }
                    .text-box {
                        width: 38px;
                        height: 38px;
                        background-color: rgba(230, 230, 230, 0.55);
                        text-align: center;
                        line-height: 38px;
                    }
                }
            }
        }
        .d-config{
          display: flex;margin-bottom: 10px; align-items: center;flex-direction: column;padding-right: 60px;font-size: 12px;
          .qx {
            padding: 0 64px;
            box-sizing: border-box;
            margin-top: 8px;
            width: 100%;
            .el-switch{
              padding: 0 8px;
            }
            .qx-item{
              padding: 5px 0;
            }
          }
          .title1{
            color: #4e4848;
            padding-left: 30px;
          }
          .title2{
            color: #847575;
            padding-left: 46px;
          }
        }
    }
    .diatwo {
        box-sizing: border-box;
        padding: 0 30px;
        .dialist {
            display: flex;
            flex-wrap: wrap;
            .dia-li {
                width: 300px;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                .name {
                    font-weight: 700;
                    margin-right: 20px;

                }
                img {
                    width: 48px;
                    height: 47px;
                }
            }
        }
        .diaimg {
            display: flex;
            flex-wrap: wrap;
            .imgbox {
                width: 300px;
                margin-bottom: 15px;
                .name {
                    font-weight: 700;

                }
                .imgli {
                    display: flex;
                    margin-top: 15px;
                    img {
                        width: 92px;
                        height: 65px;
                        margin-right: 10px;
                    }
                }
            }
        }
        .sh {
            display: flex;
            margin-top: 40px;
            textarea {
                width: 679px;
                height: 60px;
                margin-left: 10px;
            }
            
        }
        .but {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
        padding: 0 300px;
    }
    }
  }
  /deep/ .el-switch__label * {
    font-size: 12px;
  }
  </style>
  